import { Container, Row, Col } from 'react-bootstrap';

const Home = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Bienvenid@</h1>
					<p>Para ver los pedidos en curso, puedes verlos en Pedidos</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
