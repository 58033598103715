import socketIOClient from 'socket.io-client';
import { getJwt } from './jwt';

const ENDPOINT = `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}`;

export const connectSocket = callback => {
  const token = getJwt();
  const socket = socketIOClient(ENDPOINT);

  // Only for test
  // socket.onAny((event, ...args) => {
  //   console.log(event, args);
  // });

  socket.on('connect_error', err => {
    // the reason of the error, for example "xhr poll error"
    console.error(err);
    console.log(err.message);

    // some additional description, for example the status code of the initial HTTP response
    // console.log(err.description);

    // // some additional context, for example the XMLHttpRequest object
    // console.log(err.context);
  });

  return socket.on('Orders', data => {
    callback();
  });
};

export const connectSocketOrder = (orderId, callback) => {
  const socket = socketIOClient(ENDPOINT);
  return socket.on(`order-${orderId}`, data => {
    callback(data.status);
  });
};
